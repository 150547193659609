import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Footer.styles';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { useTheme } from '@/hooks/theme/useTheme';
import { ChangeLang } from '@/components/ChangeLang/ChangeLang';
import { makeHrefForPhone } from '@/utils/helpers';
import { CONTACT_US } from '@/constants/contactUs';
import { ROUTES } from '@/constants/routes';
import { useBridgeState } from '@/bridge/hooks';
import NoSsr from '@/components/NoSsr/NoSsr';
import { CustomLink } from '@/components/CustomLink/CustomLink';

export const Footer = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const { isWebView } = useBridgeState();

  if (isWebView) {
    return <S.FooterBox data-testid="footer" />;
  }

  return (
    <S.FooterBox data-testid="footer">
      <S.FooterLinks data-testid="footer-links">
        <Typography.Caption
          $variant="2"
          as={CustomLink}
          href={ROUTES.help}
          target="_blank"
        >
          {t('common.help')}
        </Typography.Caption>
        <Typography.Caption
          $variant="2"
          as={CustomLink}
          href={ROUTES.about}
          target="_blank"
        >
          {t('common.about_project')}
        </Typography.Caption>
        <Typography.Caption
          $variant="2"
          as={CustomLink}
          href={ROUTES.rules}
          target="_blank"
        >
          {t('common.placement_rules')}
        </Typography.Caption>
        <Typography.Caption
          $variant="2"
          as={CustomLink}
          href={ROUTES.terms}
          target="_blank"
        >
          {t('common.terms_of_use')}
        </Typography.Caption>
        <Typography.Caption
          $variant="2"
          as={CustomLink}
          href={ROUTES.privacy}
          target="_blank"
        >
          {t('common.privacy_policy')}
        </Typography.Caption>
        <Typography.Caption
          $variant="2"
          as={CustomLink}
          href={ROUTES.proposal}
          target="_blank"
        >
          {t('common.proposal')}
        </Typography.Caption>
      </S.FooterLinks>

      <S.FooterDivider />

      <S.FooterPhones data-testid="footer-phones">
        <S.PhoneNumber
          $variant="4"
          as="a"
          href={makeHrefForPhone(CONTACT_US.PHONE)}
        >
          {CONTACT_US.PHONE}
        </S.PhoneNumber>
      </S.FooterPhones>
      <S.MailAddress
        $variant="5"
        as="a"
        href={`mailto:${CONTACT_US.EMAIL}`}
        target="_blank"
        data-testid="mail-footer-social"
      >
        {CONTACT_US.EMAIL}
      </S.MailAddress>

      <S.FooterSocials data-testid="footer-socials">
        <S.FooterSocial
          href={CONTACT_US.FACEBOOK}
          target="_blank"
          $iconName={`facebook-footer${isDarkTheme ? '-dark' : ''}`}
          data-testid="facebook-footer-social"
        />
        <S.FooterSocial
          href={CONTACT_US.INSTAGRAM}
          target="_blank"
          $iconName={`instagram-footer${isDarkTheme ? '-dark' : ''}`}
          data-testid="instagram-footer-social"
        />
      </S.FooterSocials>

      <S.FooterChangeLang>
        <ChangeLang
          typography={Typography.Caption}
          typographyProps={{ $variant: '2' }}
        />
      </S.FooterChangeLang>

      <S.FooterDivider />

      <S.FooterResponsibility data-testid="footer-responsibility">
        {t('common.administration_is_not_responsible')}
      </S.FooterResponsibility>
      <NoSsr>
        <Typography.Caption data-testid="footer-copyright">
          © 2008-{new Date().getFullYear()} Digital Classifieds MMC. VÖEN:
          1405631661
        </Typography.Caption>
      </NoSsr>
    </S.FooterBox>
  );
};
